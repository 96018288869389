import { isRejectedWithValue } from '@reduxjs/toolkit'
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'
import { logErrorToVercel } from './initErrorLogger'

/**
 * Log a warning and show a toast!
 */
export const ErrorMiddleware: Middleware =
    (api: MiddlewareAPI) => (next) => (action) => {
        if (isRejectedWithValue(action)) {
            const metaArg = action.meta?.arg as { endpointName?: string, originalArgs?: any } || {};
            const error = {
                type: action.type,
                payload: action.payload,
                endpointName: metaArg.endpointName,
                args: metaArg.originalArgs,
                error: action.error,
            }
            logErrorToVercel("redux", error)
        }
        return next(action)
    }