import React, { useEffect, useState } from "react";
import styles from "./racers.module.css";

const Racers: React.FC = () => {
  const [startRace, setStartRace] = useState(false);

  useEffect(() => {
    const keySequence: string[] = [];
    const correctSequence = ["d", "r", "i", "v", "e", "r"];

    const handleKeydown = (event: KeyboardEvent) => {
      keySequence.push(event.key.toLowerCase());

      if (keySequence.length > correctSequence.length) {
        keySequence.shift();
      }

      if (keySequence.join("") === correctSequence.join("")) {
        setStartRace(true);
        setTimeout(() => setStartRace(false), 2000);
      }
    };

    window.addEventListener("keydown", handleKeydown);

    return () => {
      window.removeEventListener("keydown", handleKeydown);
    };
  }, []);

  return (
    <>
      {startRace && (
        <div>
          <img src="/d1.png" alt="racer-1" className={styles.racer1} />
          <img src="/d2.png" alt="racer-2" className={styles.racer2} />
        </div>
      )}
    </>
  );
};

export default Racers;
