import security from './security';
import { configureStore } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
const { getAccessTokenSilently } = security;
import qs from "qs"
import { CreateDocumentBody, DeleteDocumentParams, DuplicateDocumentParams, FetchLibraryParams, LibraryRecord, LibraryResponse, UpdateDocumentParams } from './types/library';
import { ContentTypesResponse } from './types/content';
import { AddContentToCollectionParams, AddContentToCollectionResponse, AddSourceToDocumentParams, AssociatedSourcesResponse, GetCollectionSourcesParams, GetDocumentSourcesParams, RemoveSourceFromDocumentParams } from './types/collection';
import { AgentBatchResponse, AgentCallIdsRequest, AgentPipeLineSyncRequest, AgentPollingResponse, AgentRequest, AgentResponse, AsyncAgentResponse } from './types/agent';
import { ErrorMiddleware } from './ErrorMiddleware';


const baseQuery = fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_API_URL || process.env.NEXT_PUBLIC_API_AUDIENCE,
    prepareHeaders: async (headers) => {
        const token = await getAccessTokenSilently()();
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
            headers.set('Content-Type', 'application/json');
        }
        return headers;
    },
    paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
    }
});

export const driverApi = createApi({
    reducerPath: 'driverApi',
    baseQuery: baseQuery,
    tagTypes: [
        "Tags",
        "Library",
        "Content",
        "ContentTypes",
        "DocumentSources",
        "Collections",
        "CobebaseRoot",
        "CollectionSources",
        "TagContents",
    ],
    endpoints: (builder) => ({
        getLibrary: builder.query<LibraryResponse, FetchLibraryParams>({
            query: (search) => {
                return {
                    url: "/content/",
                    params: search,
                }
            },
            providesTags: ["Library"]
        }),
        getContent: builder.query<LibraryRecord, { contentId: string }>({
            query: ({ contentId }) => {
                return {
                    url: `/content/${contentId}`,
                }
            },
            providesTags: ["Content"]
        }),
        updateContent: builder.mutation<LibraryRecord, UpdateDocumentParams>({
            query(params) {
                return {
                    url: `/content/${params.contentId}/`,
                    method: 'PUT',
                    body: {
                        content_name: params.name,
                        content: params.content,
                    },
                }
            },
            invalidatesTags: ["Content", "Library"]
        }),
        createDocument: builder.mutation<LibraryRecord, CreateDocumentBody>({
            query: (body) => {
                return {
                    url: "/content/",
                    method: "POST",
                    body,
                }
            },
            invalidatesTags: ["Library"]
        }),
        deleteDocument: builder.mutation<void, DeleteDocumentParams>({
            query: (params) => {
                return {
                    url: `/content/${params.id}`,
                    method: "DELETE",
                }
            },
            invalidatesTags: ["Library"]
        }),
        getTags: builder.query<TagGetResponse, TagGetParams>({
            query: (params) => {
                return {
                    url: "/tags/",
                    params: params,
                }
            },
            providesTags: ["Tags"]
        }),
        getTagContents: builder.query<LibraryResponse, { tagId: string }>({
            query: ({ tagId }) => {
                return {
                    url: `/tags/${tagId}/content`,
                }
            },
            providesTags: ["TagContents"]
        }),
        getContentTypes: builder.query<ContentTypesResponse, void>({
            query: () => "/content/types",
            providesTags: ["ContentTypes"]
        }),
        getCodebaseRoot: builder.query<LibraryRecord, { contentId: string }>({
            query: ({ contentId }) => `/content/${contentId}/codebase-root`,
            providesTags: ["CobebaseRoot"]
        }),
        postCallIdResults: builder.mutation<AgentBatchResponse, AgentCallIdsRequest>({
            query: (body) => {
                return {
                    url: `/agent_pipelines/async/batch`,
                    method: "POST",
                    body,
                }
            },
        }),
        postAgentPipelineAsync: builder.mutation<AsyncAgentResponse, AgentPipeLineSyncRequest>({
            query: (body) => {
                return {
                    url: `/agent_pipelines/async`,
                    method: "POST",
                    body,
                }
            },
        }),
        postAgentPipelineSync: builder.mutation<AgentResponse, AgentPipeLineSyncRequest>({
            query: (body) => {
                return {
                    url: `/agent_pipelines/sync`,
                    method: "POST",
                    body,
                }
            },
        }),
        getAgentCallStatus: builder.query<AsyncAgentResponse, { callId: string }>({
            query: ({ callId }) => `/agent_pipelines/async/${callId}/`,
        }),
        createTag: builder.mutation<TagResult, Tag>({
            query(body) {
                return {
                    url: `/tags/`,
                    method: 'POST',
                    body,
                }
            },
            invalidatesTags: ["Tags"],
        }),
        addContentToCollection: builder.mutation<AddContentToCollectionResponse, AddContentToCollectionParams>({
            query(params) {
                return {
                    url: `/tags/${params.collectionId}/content/${params.contentId}`,
                    method: 'POST',
                    body: {
                        include: true,
                    },
                }
            },
            invalidatesTags: ["DocumentSources"]
        }),
        addSourceToDocument: builder.mutation<void, AddSourceToDocumentParams>({
            query(params) {
                return {
                    url: `/content/${params.contentId}/document-sources/batch/`,
                    method: 'POST',
                    body: {
                        sources: params.sources,
                    },
                }
            },
            invalidatesTags: ["DocumentSources"]
        }),
        removeSourceFromDocument: builder.mutation<void, RemoveSourceFromDocumentParams>({
            query(params) {
                return {
                    url: `/content/${params.contentId}/document-sources/batch/`,
                    method: 'DELETE',
                    body: {
                        source_ids: params.sourcesIds,
                    },
                }
            },
            invalidatesTags: ["DocumentSources"]
        }),
        getDocumentSources: builder.query<AssociatedSourcesResponse, GetDocumentSourcesParams>({
            query: ({ documentId }) => `/content/${documentId}/document-sources`,
            providesTags: ["DocumentSources"]
        }),
        getCollectionSources: builder.query<AssociatedSourcesResponse, GetCollectionSourcesParams>({
            query: ({ collectionId }) => `/tags/${collectionId}/content`,
            providesTags: ["CollectionSources"]
        }),
        addTagToContent: builder.mutation<void, AssociateTagWithContentParams>({
            query(body) {
                return {
                    url: `/tags/${body.tagId}/content/${body.documentId}`,
                    method: 'POST',
                    body: {
                        include: false
                    }
                }
            },
            invalidatesTags: ["Tags", "Library"]
        }),
        updateTag: builder.mutation<void, UpdateTagParams>({
            query(params) {
                return {
                    url: `/tags/${params.tagId}`,
                    method: 'PUT',
                    body: params.tag,
                }
            },
            invalidatesTags: ["Tags", "Library"]
        }),
        deleteTag: builder.mutation<void, DeleteTagParams>({
            query(params) {
                return {
                    url: `/tags/${params.tagId}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ["Tags", "Library"]
        }),
        removeTagFromContent: builder.mutation<void, AssociateTagWithContentParams>({
            query(body) {
                return {
                    url: `/tags/${body.tagId}/content/${body.documentId}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ["Tags", "Library"]
        }),
        uploadCodebase: builder.mutation<LibraryRecord, { file_path: string }>({
            query: ({ file_path }) => {
                return {
                    url: "/upload/codebase",
                    method: "POST",
                    body: { file_path },
                }
            },
            invalidatesTags: ["Library"]
        }),
        uploadPdf: builder.mutation<LibraryRecord, { file_path: string }>({
            query: ({ file_path }) => {
                return {
                    url: "/upload/pdf",
                    method: "POST",
                    body: { file_path },
                }
            },
            invalidatesTags: ["Library"]
        }),
    }),
})

export const apiStore = configureStore({
    reducer: {
        [driverApi.reducerPath]: driverApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(driverApi.middleware).concat(ErrorMiddleware),
})

export const {
    useGetLibraryQuery,
    useGetTagsQuery,
    useGetDocumentSourcesQuery,
    useLazyGetCollectionSourcesQuery,
    useGetContentTypesQuery,
    useGetCodebaseRootQuery,
    useAddSourceToDocumentMutation,
    useAddContentToCollectionMutation,
    useRemoveSourceFromDocumentMutation,
    useCreateTagMutation,
    useAddTagToContentMutation,
    useUpdateTagMutation,
    useDeleteDocumentMutation,
    useGetTagContentsQuery,
    usePostAgentPipelineSyncMutation,
    usePostAgentPipelineAsyncMutation,
    usePostCallIdResultsMutation,
    useLazyGetAgentCallStatusQuery,
    useRemoveTagFromContentMutation,
    useDeleteTagMutation,
    useCreateDocumentMutation,
    useUpdateContentMutation,
    useGetContentQuery,
    useUploadCodebaseMutation,
    useUploadPdfMutation,
} = driverApi